<template>
    <div id="home">
        <div class = "sidebar">
            <div class = "sidebar_top">
                <div class = "intro">
                    <div class = "avatar">
                        <img src="../assets/wsj-avatar.svg" alt="">
                    </div>
                    <div class = "username">
                        <input type="text" v-model="datainfo.username" @blur="handleUser">
                    </div>
                    <div class = "signout">Sign Out</div>
                </div>
                <div class = "section1">
                    <div class = "section_title">My Account</div>
                    <div class = "section_box">
                        <ul>
                            <li v-for = "(item,index) in section1" :key = "index" :class = "section1_index == index?'active':''">{{ item.name }}</li>
                        </ul>
                    </div>
                </div>
                <div class = "section2">
                    <div class = "section_title">Help</div>
                    <div class = "section_box">
                        <ul>
                            <li v-for = "(item,index) in section2" :key = "index">{{ item.name }}</li>
                        </ul>
                    </div>
                </div>
                <div class = "section3">
                    <div class = "section_title">My WSJ</div>
                    <div class = "section_box">
                        <ul>
                            <li v-for = "(item,index) in section3" :key = "index">{{ item.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class = "main_content">
            <div class = "con_top">
                <p>Subscription Details</p>
                <div>WSJ.COM</div>
            </div>
            <div class = "con_center">
                <p>New:</p>
                <span>Text “Chat” to +1 (877)-975-8398 to Register for SMS Customer Support</span>
                <div class = "close">×</div>
            </div>
            <div class = "con_bot">
                <div class = "con_bot_top">
                    <p>WSJ</p>
                </div>
                <div class = "con_bot_b_box">
                    <div class = "con_bot_b_l">
                        <div class = "section1">
                            <div class = "sec_26">
                                <p v-show = "sec_26_flag == false" @click = "handlesec26">{{ datainfo.sec_26 }}</p>
                                <input type="text" ref="sec_26_input" v-model = "datainfo.sec_26" v-show="sec_26_flag == true" @blur = "handlesec26blur">
                            </div>
                            <p>Edit</p>
                        </div>
                        <div class = "section2">
                            <p class = "sec_title">Subscription</p>
                            <div class = "sec_desc">
                                <p v-show="sec_desc_flag == false"  @click = "handlesecdesc">{{ datainfo.sec_desc }}</p>
                                <input type="text" v-model = "datainfo.sec_desc" v-show="sec_desc_flag == true"  @blur = "handlesecdescblur">
                            </div>
                            <div class = "sec_box">
                                <div class = "box_item">
                                    <div class = "item_l">
                                        <p v-show = "sec_5_flag == false" @click = "handlesec5">{{ datainfo.sec_5 }}:</p>
                                        <input type="text" v-model = "datainfo.sec_5" v-show="sec_5_flag == true"  @blur = "handlesec5blur">
                                    </div>
                                    <div class = "item_r">
                                        <p v-show = "sec_6_flag == false" @click = "handlesec6">{{ datainfo.sec_6 }}</p>
                                        <input type="text" v-model = "datainfo.sec_6" v-show="sec_6_flag == true"  @blur = "handlesec6blur">
                                    </div>
                                </div>
                                <div class = "box_item">
                                    <div class = "item_l">
                                        <p v-show = "sec_7_flag == false" @click = "handlesec7">{{ datainfo.sec_7 }}:</p>
                                        <input type="text" v-model = "datainfo.sec_7" v-show="sec_7_flag == true"  @blur = "handlesec7blur">
                                    </div>
                                    <div class = "item_r">
                                        <p v-show = "sec_8_flag == false" @click = "handlesec8">{{ datainfo.sec_8 }}</p>
                                        <input type="text" v-model = "datainfo.sec_8" v-show="sec_8_flag == true"  @blur = "handlesec8blur">
                                    </div>
                                </div>
                            </div>
                            <div class = "sec_21">
                                <p v-show="sec_21_flag == false" @click = "handlesec21">{{ datainfo.sec_21 }}</p>
                                <input type="text" v-model = "datainfo.sec_21" v-show="sec_21_flag == true"  @blur = "handlesec21blur">
                            </div>
                            <div class = "sec_22">
                                <p v-show="sec_22_flag == false"  @click = "handlesec22">{{ datainfo.sec_22 }}</p>
                                <input type="text" v-model = "datainfo.sec_22" v-show="sec_22_flag == true"  @blur = "handlesec22blur">
                            </div>
                            <!-- <div class = "sec_box2">
                                <p>Subscription Benefits</p>
                                <div class = "box_item">
                                    <div class = "item">
                                        <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                        <p>Full Access to WSJ.com, WSJ Context, Mobile and Tablet Apps</p>
                                    </div>
                                    <div class = "item">
                                        <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                        <p>Monday-Saturday Home Delivery of the WSJ Newspaper, Weekend Print Edition and WSJ Magazine</p>
                                    </div>
                                    <div class = "item">
                                        <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                        <p>Trusted News from Award-Winning Journalists</p>
                                    </div>
                                    <div class = "item">
                                        <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                        <p>Classroom Learning Tools for Professors & Students</p>
                                    </div>
                                    <div class = "item">
                                        <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                        <p>Informative Podcasts and Newsletters</p>
                                    </div>
                                    <div class = "item">
                                        <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                        <p>Breaking News Alerts</p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class = "section3">
                            <div class = "item1">
                                <div class = "sec_box2">
                                    <p>Subscription Benefits</p>
                                    <div class = "box_item">
                                        <div class = "item">
                                            <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                            <p>Full Access to WSJ.com, WSJ Context, Mobile and Tablet Apps</p>
                                        </div>
                                        <div class = "item">
                                            <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                            <p>Monday-Saturday Home Delivery of the WSJ Newspaper, Weekend Print Edition and WSJ Magazine</p>
                                        </div>
                                        <div class = "item">
                                            <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                            <p>Trusted News from Award-Winning Journalists</p>
                                        </div>
                                        <div class = "item">
                                            <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                            <p>Classroom Learning Tools for Professors & Students</p>
                                        </div>
                                        <div class = "item">
                                            <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                            <p>Informative Podcasts and Newsletters</p>
                                        </div>
                                        <div class = "item">
                                            <svg class="subcription-detail-benefit-checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.3745 0.713867L19.4352 1.77453L7.14933 14.0604L0.469727 7.38076L1.53039 6.3201L7.14856 11.9377L18.3745 0.713867Z" fill="#0274B6"></path></svg>
                                            <p>Breaking News Alerts</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class = "sec_21">
                                    <p v-show="sec_21_flag == false" @click = "handlesec21">{{ datainfo.sec_21 }}</p>
                                    <input type="text" v-model = "datainfo.sec_21" v-show="sec_21_flag == true"  @blur = "handlesec21blur">
                                </div>
                                <div class = "sec_22">
                                    <p v-show="sec_22_flag == false"  @click = "handlesec22">{{ datainfo.sec_22 }}</p>
                                    <input type="text" v-model = "datainfo.sec_22" v-show="sec_22_flag == true"  @blur = "handlesec22blur">
                                </div> -->
                                <p class = "sec3_title">Print Information</p>
                                <div class = "sec_num">
                                    <p class = "num_text">Account Number: </p>
                                    <div class = "sec_23">
                                        <p v-show="sec_23_flag == false"  @click = "handlesec23">{{ datainfo.sec_23 }}</p>
                                        <input type="text" v-model = "datainfo.sec_23" v-show="sec_23_flag == true" @blur = "handlesec23blur">
                                    </div>
                                </div>
                                <!-- <p class = "sec3_text">Access the digital version of print edition: <span>WSJ e-Edition</span></p>
                                <p class = "sec3_text">Our newspaper are 100% sourced from sustainable certified mills.</p> -->
                            </div>
                            <div class = "item2">
                                <p class = "item2_title">Delivery Status</p>
                                <div class = "cale_box">
                                    <el-calendar >
                                        <template slot="dateCell" slot-scope="{data}">
                                            <div class = "holiday-cell-box" :class = "isSpeciaDate(data.day)">
                                                <div class="holiday-cell"  @click="selectDate(data)">
                                                    <span>{{ data.day.split('-')[2] }}</span>
                                                </div>
                                            
                                            </div>
                                        </template>
                                    </el-calendar>
                                    <div class = "legend_box">
                                        <div class = "le_item">
                                            <div class = "le_i_color red"></div>
                                            <div class = "sec_30">
                                                <p v-show="sec_30_flag == false" @click = "handlesec30">{{ datainfo.sec_30 }}</p>
                                                <input type="text" v-model = "datainfo.sec_30" v-show="sec_30_flag == true"  @blur = "handlesec30blur">
                                            </div>
                                        </div>
                                        <div class = "le_item">
                                            <div class = "le_i_color blue"></div>
                                            <div class = "sec_30">
                                                <p v-show="sec_31_flag == false" @click = "handlesec31">{{ datainfo.sec_31 }}</p>
                                                <input type="text" v-model = "datainfo.sec_31" v-show="sec_31_flag == true"  @blur = "handlesec31blur">
                                            </div>
                                        </div>
                                        <div class = "le_item">
                                            <div class = "le_i_color green"></div>
                                            <div class = "sec_30">
                                                <p v-show="sec_32_flag == false" @click = "handlesec32">{{ datainfo.sec_32 }}</p>
                                                <input type="text" v-model = "datainfo.sec_32" v-show="sec_32_flag == true"  @blur = "handlesec32blur">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class = "item_box" @click.stop="handleselect">
                                    <div class = "item_item" v-for = "(item,index) in datainfo.dataLiist" :key = "index">
                                        <p>{{ item.time }}</p>
                                        <div class = "line" :class = "item.color == 0?'red':''" v-if = "item.color == 0"></div>
                                        <div class = "line" :class = "item.color == 1?'blue':''" v-else-if = "item.color == 1"></div>
                                        <div class = "line" :class = "item.color == 2?'green':''" v-else ></div>
                                        <p  class = "de">{{  item.delivery }}</p>
                                        <div class = "close" @click.stop = "handledataclose(index)">×</div>
                                    </div>
                                </div> -->
                            </div>
                            <div class = "item3">
                                <div class = "item_top">
                                    <p>Weekday Delivery</p>
                                    <!-- <span>Edit</span> -->
                                </div>
                                <div class = "sec_24">
                                    <p v-show="sec_24_flag == false" @click = "handlesec24">{{ datainfo.sec_24 }}</p>
                                    <input type="text" v-model = "datainfo.sec_24" v-show="sec_24_flag == true" @blur = "handlesec24blur">
                                </div>
                                <div class = "sec_25">
                                    <p v-show="sec_25_flag == false" @click = "handlesec25">{{ datainfo.sec_25 }}</p>
                                    <input type="text" v-model = "datainfo.sec_25" v-show="sec_25_flag == true" @blur = "handlesec25blur">
                                </div>
                            </div>
                            <div class = "item3">
                                <div class = "item_top">
                                    <p>Weekend Delivery(optional)</p>
                                    <!-- <span>Edit</span> -->
                                </div>
                                <div class = "sec_24">
                                    <p>Choose a different address for weekends.</p>
                                </div>
                            </div>
                            <div class = "item3">
                                <div class = "item_top">
                                    <p>Suspend Delivery</p>
                                    <span>Schedule</span>
                                </div>
                                <div class = "sec_24">
                                    <p>You can temporarily suspend delivery as needed. It takes two publishing days in advance to schedule. <span>Why?</span> </p>
                                </div>
                            </div>
                            <div class = "item3">
                                <div class = "item_top">
                                    <p>Customer Support</p>
                                    <span></span>
                                </div>
                                <div class = "sec_24">
                                    <p>Subscribe to our <span>SMS Customer Support</span> center and manage your delivery through your mobile. </p>
                                    <p>Delivery missing? <span>Report a delivery issue.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class = "con_bot_b_r">
                        <div class = "r_top">
                            <div class = "sec_9">
                                <p v-show="sec_9_flag == false"  @click = "handlesec9">{{ datainfo.sec_9 }}</p>
                                <input type="text" v-model="datainfo.sec_9" v-show = "sec_9_flag == true"   @blur = "handlesec9blur">
                            </div>
                            <div class = "top_c">
                                <div class = "item">
                                    <div class = "sec_10">
                                        <p v-show="sec_10_flag == false" @click = "handlesec10">{{ datainfo.sec_10 }}</p>
                                        <input type="text" v-model="datainfo.sec_10" v-show = "sec_10_flag == true" @blur = "handlesec10blur">
                                    </div>
                                    <div class = "sec_11">
                                        <p v-show="sec_11_flag == false" @click = "handlesec11">{{ datainfo.sec_11 }}</p>
                                        <input type="text" v-model="datainfo.sec_11" v-show = "sec_11_flag == true" @blur = "handlesec11blur"> 
                                    </div>
                                    
                                </div>
                                <div v-if = "datainfo.secList2.length != 0">
                                    <div class = "item item6" v-for = "(item,index) in datainfo.secList2" :key = "index">
                                        <div class = "sec_27" >
                                            <p v-show="item.flag == false" @click = "handlesec29Index(index)">{{ item.insert }}</p>
                                            <input type="text" v-model="item.insert" v-show = "item.flag == true" @blur = "handlesec29blur(index)">
                                            <div class = "close" @click = "handledata29close(index)">×</div>
                                        </div>
                                    </div>
                                </div>
                                <div class = "item item5" @click = "handlesec29"></div>
                                <div class = "item" :class = "datainfo.secList1.length != 0?'item3':'item4'">
                                    <div class = "sec_12">
                                        <p v-show="sec_12_flag == false" @click = "handlesec12">{{ datainfo.sec_12 }}</p>
                                        <input type="text" v-model="datainfo.sec_12" v-show = "sec_12_flag == true" @blur = "handlesec12blur">
                                    </div>
                    
                                </div>
                                <div v-if = "datainfo.secList1.length != 0">
                                    <div class = "item item2" v-for = "(item,index) in datainfo.secList1" :key = "index">
                                        <div class = "sec_27" >
                                            <p v-show="item.flag == false" @click = "handlesec27Index(index)">{{ item.insert }}</p>
                                            <input type="text" v-model="item.insert" v-show = "item.flag == true" @blur = "handlesec27blur(index)">
                                            <div class = "close" @click = "handledata27close(index)">×</div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class = "item item2" @click = "handlesec27"></div>
                            </div>
                        </div>
                        <div class = "pay_insert"  @click = "handlesec28"></div>
                        <div class = "pay_insert" :class = "datainfo.secList.length!=0?'active':''" v-if = "datainfo.secList.length!=0">
                            <div >
                                <div class = "sec_28" v-for = "(item,index) in datainfo.secList" :key = "index">
                                    <p v-show="item.flag == false" @click="handlesec28Index(index)">{{ item.insert }}</p>
                                    <input type="text" v-model="item.insert" v-show = "item.flag == true" @blur.stop = "handlesec28blur(index)">
                                    <div class = "close" @click = "handledata28close(index)">×</div>
                                </div>
                            </div>
                            
                        </div>
                        <div class = "pay" :class = "datainfo.insert?'active':''">
                            <p class = "pay_title">Payment and Billing</p>
                            <p class = "pay_desc">Update your credit card or payment<br> method and view your billing history.</p>
                            <div class = "pay_method">
                                <div class = "pay_m_top">
                                    <p class = "pay_m_title">Payment Method</p>
                                    <!-- <p class = "pay_m_text">Edit</p> -->
                                </div>
                                <div class = "pay_m_bot">
                                    <div class = "pay_img">
                                        <div class = "img_text" v-html = "datainfo.pay_img" v-show = "pay_img_flag == false" @click.stop = "handlesecfile"></div>
                                        <input type="file"  ref="fileInput"  @click.stop v-show = "pay_img_flag == true" @change = "handleFileChange">
                                    </div>
                                    <div class = "pay_box">
                                        <div class = "sec_13">
                                            <p v-show="sec_13_flag == false" @click = "handlesec13">{{ datainfo.sec_13 }}</p>
                                            <input type="text"   v-model="datainfo.sec_13" v-show = "sec_13_flag == true" @blur = "handlesec13blur">
                                        </div>
                                        <div class = "sec_14">
                                            <p v-show="sec_14_flag == false" @click = "handlesec14">{{ datainfo.sec_14 }}</p>
                                            <input type="text" v-model="datainfo.sec_14" v-show = "sec_14_flag == true" @blur = "handlesec14blur">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class = "bill_his">
                                <div class = "his_top">
                                    <p class = "pay_m_title">Billing History</p>
                                    <!-- <p class = "pay_m_text">View All</p> -->
                                </div>
                                <div class = "top_c">
                                    <div class = "item">
                                        <div class = "sec_15">
                                            <p v-show="sec_15_flag == false" @click = "handlesec15">{{ datainfo.sec_15 }}</p>
                                            <input type="text" v-model="datainfo.sec_15" v-show = "sec_15_flag == true" @blur = "handlesec15blur">
                                        </div>
                                        <div class = "sec_16">
                                            <p v-show="sec_16_flag == false" @click = "handlesec16">{{ datainfo.sec_16 }}</p>
                                            <input type="text" v-model="datainfo.sec_16" v-show = "sec_16_flag == true" @blur = "handlesec16blur">
                                        </div>
                                        <span>|</span>
                                        <div class = "sec_17">
                                            <p v-show="sec_17_flag == false" @click = "handlesec17">{{ datainfo.sec_17 }}</p>
                                            <input type="text" v-model="datainfo.sec_17" v-show = "sec_17_flag == true" @blur = "handlesec17blur">
                                        </div>
                                    </div>
                                    <div class = "item">
                                        <div class = "sec_15">
                                            <p v-show="sec_18_flag == false" @click = "handlesec18">{{ datainfo.sec_18 }}</p>
                                            <input type="text" v-model="datainfo.sec_18" v-show = "sec_18_flag == true" @blur = "handlesec18blur">
                                        </div>
                                        <div class = "sec_16">
                                            <p v-show="sec_19_flag == false" @click = "handlesec19">{{ datainfo.sec_19 }}</p>
                                            <input type="text" v-model="datainfo.sec_19" v-show = "sec_19_flag == true" @blur = "handlesec19blur">
                                        </div>
                                        <span>|</span>
                                        <div class = "sec_17">
                                            <p v-show="sec_20_flag == false" @click = "handlesec20">{{ datainfo.sec_20 }}</p>
                                            <input type="text" v-model="datainfo.sec_20" v-show = "sec_20_flag == true" @blur = "handlesec20blur">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <div class = "con_bot_b_footer">
                <div class = "container">
                    <p>Advertise <span>|</span>Help Center <span>|</span>Privacy Notice <span>|</span>Cookie Notice <span>|</span>Terms of Use <span>|</span>Contact Us</p>
                <p>© 2024 Dow Jones & Company, Inc. All Rights Reserved</p>
                </div>
            </div>
        </div>
        <div class = "float">
            <img src="../assets/chat-closed.svg" alt="chat" class="floating-button__xHCqB">
        </div>

        <div class = "select_popup" v-show = "select_flag">
            <div class = "select_content">
                <div class = "close" @click = "handleselectclose">×</div>
                <div class = "select_box">
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" @click = "handlesubmit">确认</el-button>
                </div>
            </div>
        </div>

        <div class = "select_popup select_popup1" v-show = "sec_27_flag">
            <div class = "select_content">
                <div class = "close" @click = "handleselectclose27">×</div>
                <div class = "select_box">
                    <el-input v-model="sec27Click.insert" placeholder="请输入内容"></el-input>
                    <el-button type="primary" @click = "handlesubmit27">确认</el-button>
                </div>
            </div>
        </div>

        <div class = "select_popup select_popup1" v-show = "sec_28_flag">
            <div class = "select_content">
                <div class = "close" @click = "handleselectclose28">×</div>
                <div class = "select_box">
                    <el-input v-model="sec28Click.insert" placeholder="请输入内容"></el-input>
                    <el-button type="primary" @click = "handlesubmit28">确认</el-button>
                </div>
            </div>
        </div>
        <div class = "select_popup select_popup1" v-show = "sec_29_flag">
            <div class = "select_content">
                <div class = "close" @click = "handleselectclose29">×</div>
                <div class = "select_box">
                    <el-input v-model="sec29Click.insert" placeholder="请输入内容"></el-input>
                    <el-button type="primary" @click = "handlesubmit29">确认</el-button>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    data(){
        return{
            
            options: [
                {
                    value: '',
                    label: '默认'
                },
                {
                    value: 'red',
                    label: '红色'
                }, 
                {
                    value: 'blue',
                    label: '蓝色'
                }, 
                {
                    value: 'green',
                    label: '绿色'
                },
            ],
            value: '',
            select_flag:false,
            sec27Click:{
                insert:'',
                flag:false,
            },
            sec28Click:{
                insert:'',
                flag:false,
            },
            sec29Click:{
                insert:'',
                flag:false,
            },
            dataList:{
                date:'',
                color:'',
            },
            datainfo:{
                specialDates:[],
                username:'Christine',
                sec_desc:'The Wall Street Journal Print, Digital + WSJ Context',
                sec_5:'Next Charge',
                sec_6:'$9.99',
                sec_7:'Renewal Date',
                sec_8:'Dec 10, 2024',
                sec_9:'Manage Subscription',
                sec_10:'Change Subscription',
                sec_11:'Adjust which products you have access to',
                sec_12:'Cancel Subscription',
                sec_13:'Master Card ending in 5631',
                sec_14:'Expires 07/26',
                sec_15:'Past Charge',
                sec_16:'Nov 14, 2024',
                sec_17:'$10.93',
                sec_18:'Past Charge',
                sec_19:'Oct 8, 2024 ',
                sec_20:'$10.93',
                sec_21:'Print Delivery Settings',
                sec_22:'Change your delivery address, schedule a delivery suspension, or report an issue.',
                sec_23:'100424893375',
                sec_24:'Your paper will be sent to this address',
                sec_25:'47 Fairview ave, Atherton , CA 94027 US',
                sec_26:'Print Delivery Suspended From Nov 18 - Nov 21.',
                sec_27:'',
                sec_30:'Regular Delivery',
                sec_31:'No Delivery (Christmas Day)',
                sec_32:'No Delivery',
                secList:[],
                secList1:[],
                secList2:[],
                insert:'',
                dataLiist:[
                    {
                       time:'Today',
                       color:0,
                       delivery:'Regular Delivery',

                    },
                    {
                       time:'Dec 25',
                       color:1,
                       delivery:'No Delivery (Christmas Day)',
                    },
                    {
                       time:'Dec 26',
                       color:2,
                       delivery:'No Delivery',
                    },
                ],
                pay_img:'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="12" r="7" fill="#ED0006"></circle><circle cx="16" cy="12" r="7" fill="#F9A000"></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M12 17.7453C13.8135 16.4803 15 14.3787 15 11.9999C15 9.6212 13.8135 7.51958 12 6.25464C10.1865 7.51958 9 9.6212 9 11.9999C9 14.3787 10.1865 16.4803 12 17.7453Z" fill="#FF5E00"></path></svg>',

            },
            sec_13_list:[
                {
                    text:'',
                    flag:false,
                }
            ],
            sec_desc_flag:false,
            sec_5_flag:false,
            sec_6_flag:false,
            sec_7_flag:false,
            sec_8_flag:false,
            sec_9_flag:false,
            sec_10_flag:false,
            sec_11_flag:false,
            sec_12_flag:false,
            sec_13_flag:false,
            sec_14_flag:false,
            sec_15_flag:false,
            sec_16_flag:false,
            sec_17_flag:false,
            sec_18_flag:false,
            sec_19_flag:false,
            sec_20_flag:false,
            sec_21_flag:false,
            sec_22_flag:false,
            sec_23_flag:false,
            sec_24_flag:false,
            sec_25_flag:false,
            sec_26_flag:false,
            sec_27_flag:false,
            sec_28_flag:false,
            sec_29_flag:false,
            sec_30_flag:false,
            sec_31_flag:false,
            sec_32_flag:false,
            pay_img_flag:false,
            section1_index:2,
            section1:[
                {
                    name:'Overview'
                },
                {
                    name:'Account Settings'
                },
                {
                    name:'Manage / Cancel Subscription(s)'
                },
                {
                    name:'Link Print Subscription'
                },
                {
                    name:'Payment Method(s)'
                },
                {
                    name:'Suspend Subscription'
                },
                {
                    name:'Report Delivery Issue'
                },
                {
                    name:'Register for SMS Customer Support'
                },
            ],
            section2:[
                {
                    name:'Help Center'
                },
                {
                    name:'Contact Us'
                }
            ],
            section3:[
                {
                    name:"Today's Print Edition"
                },
                {
                    name:'AI Portrait'
                },
                {
                    name:"Audio Queue"
                },
                {
                    name:"Saved"
                },
                {
                    name:"Watchlist"
                },
                {
                    name:"Newsletters"
                },
                {
                    name:"Comments"
                },
                {
                    name:"The Journal Collection"
                },
                {
                    name:"WSJ.com"
                },
                {
                    name:"WSJ e-Edition"
                },
                {
                    name:"Alerts"
                }
            ],
        }
    },
    mounted(){
        document.addEventListener('click', this.handleClickOutside);
        let Data =  localStorage.getItem('Data');
        if(Data){
            this.datainfo = JSON.parse(Data)
        }
    },
    methods:{
        handleClickOutside(event) {
            console.log(event.target);
            // 检查点击事件的目标是否不是文件选择框或其子元素
            if (
                this.$refs.fileInput &&
                !this.$refs.fileInput.contains(event.target)
            ) {
                this.pay_img_flag = false; // 隐藏文件选择框
            }
        },
        isSpeciaDate(date){
            let _color 
            if(this.datainfo.specialDates.length > 0){
                 _color  =  this.datainfo.specialDates.find(specialDate => {
                    return specialDate.date === date;
                });
            }else{
                 _color  = ''
            }
           
            return _color ? _color.color : '';
        },
        selectDate(data) {
            this.select_flag = true;
            this.dataList.date = data.day
        },
        cellClassName({ date }) {
            console.log(date);
            const formattedDate  = this.formatDate(date);
            const index = this.datainfo.markedDates.indexOf(formattedDate);
            console.log(index,787878);
            return index !== -1 ? this.datainfo.colors[index % this.datainfo.colors.length] : '';
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        handleUser(){
            this.LocalStorage()
        },
        handlesec26(){
            this.sec_26_flag = true
        },
        handlesec26blur(){
            if(this.datainfo.sec_26){
                this.sec_26_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesecdesc(){
            this.sec_desc_flag = true
        },
        handlesecdescblur(){
            this.sec_desc_flag = false
            this.LocalStorage()
        },
        handlesec5(){
            this.sec_5_flag = true
        },
        handlesec5blur(){
            if(this.datainfo.sec_5){
                this.sec_5_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec6(){
            this.sec_6_flag = true
        },
        handlesec6blur(){
            if(this.datainfo.sec_6){
                this.sec_6_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec7(){
            this.sec_7_flag = true
        },
        handlesec7blur(){
            if(this.datainfo.sec_7){
                this.sec_7_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec8(){
            this.sec_8_flag = true
        },
        handlesec8blur(){
            if(this.datainfo.sec_8){
                this.sec_8_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec9(){
            this.sec_9_flag = true
        },
        handlesec9blur(){
            if(this.datainfo.sec_9){
                this.sec_9_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec10(){
            this.sec_10_flag = true
        },
        handlesec10blur(){
            if(this.datainfo.sec_10){
                this.sec_10_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec11(){
            this.sec_11_flag = true
        },
        handlesec11blur(){
            if(this.datainfo.sec_11){
                this.sec_11_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec12(){
            this.sec_12_flag = true
        },
        handlesec12blur(){
            if(this.datainfo.sec_12){
                this.sec_12_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec13(){
            this.sec_13_flag = true
        },
        handlesec13blur(){
            if(this.datainfo.sec_13){
                this.sec_13_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },
        handlesec14(){
            this.sec_14_flag = true
        },
        handlesec14blur(){
            if(this.datainfo.sec_14){
                this.sec_14_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec15(){
            this.sec_15_flag = true
        },
        handlesec15blur(){
            if(this.datainfo.sec_15){
                this.sec_15_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec16(){
            this.sec_16_flag = true
        },
        handlesec16blur(){
            if(this.datainfo.sec_16){
                this.sec_16_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec17(){
            this.sec_17_flag = true
        },
        handlesec17blur(){
            if(this.datainfo.sec_17){
                this.sec_17_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec18(){
            this.sec_18_flag = true
        },
        handlesec18blur(){
            if(this.datainfo.sec_18){
                this.sec_18_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec19(){
            this.sec_19_flag = true
        },
        handlesec19blur(){
            if(this.datainfo.sec_19){
                this.sec_19_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec20(){
            this.sec_20_flag = true
        },
        handlesec20blur(){
            if(this.datainfo.sec_20){
                this.sec_20_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec21(){
            this.sec_21_flag = true
        },
        handlesec21blur(){
            if(this.datainfo.sec_21){
                this.sec_21_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec22(){
            this.sec_22_flag = true
        },
        handlesec22blur(){
            if(this.datainfo.sec_22){
                this.sec_22_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec23(){
            this.sec_23_flag = true
        },
        handlesec23blur(){
            if(this.datainfo.sec_23){
                this.sec_23_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec24(){
            this.sec_24_flag = true
        },
        handlesec24blur(){
            if(this.datainfo.sec_24){
                this.sec_24_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec25(){
            this.sec_25_flag = true
        },
        handlesec25blur(){
            if(this.datainfo.sec_25){
                this.sec_25_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec30(){
            this.sec_30_flag = true
        },
        handlesec30blur(){
            if(this.datainfo.sec_30){
                this.sec_30_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec31(){
            this.sec_31_flag = true
        },
        handlesec31blur(){
            if(this.datainfo.sec_31){
                this.sec_31_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec32(){
            this.sec_32_flag = true
        },
        handlesec32blur(){
            if(this.datainfo.sec_32){
                this.sec_32_flag = false
                this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
        },

        handlesec27(){
            this.sec_27_flag = true
        },
        handlesec27Index(index){
            this.datainfo.secList1[index].flag = true
        },
        handlesec27blur(index){
            if(this.datainfo.secList1[index].insert){
                this.datainfo.secList1[index].flag = false
            this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
            this.LocalStorage()
        },
        handledata27close(index){
            this.datainfo.secList1.splice(index,1)
            this.LocalStorage()
        },
        handlesubmit27(){
            this.datainfo.secList1.push(this.sec27Click)
            let data = {
                insert:'',
                flag:false,
            }
            this.sec27Click = data
            this.sec_27_flag = false
            this.LocalStorage()
        },
        handleselectclose27(){
            this.sec_27_flag = false
        },
        handlesec28(){
            this.sec_28_flag = true
        },
        handlesec28Index(index){
            this.datainfo.secList[index].flag = true
        },
        handlesec28blur(index){
            if(this.datainfo.secList[index].insert){
                this.datainfo.secList[index].flag = false
            this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
            this.LocalStorage()
        },
        handledata28close(index){
            this.datainfo.secList.splice(index,1)
            this.LocalStorage()
        },
        handlesubmit28(){
            this.datainfo.secList.push(this.sec28Click)
            let data = {
                insert:'',
                flag:false,
            }
            this.sec28Click = data
            this.sec_28_flag = false
            this.LocalStorage()
        },
        handleselectclose28(){
            this.sec_28_flag = false
        },
        handlesec29(){
            this.sec_29_flag = true
        },
        handlesec29Index(index){
            this.datainfo.secList2[index].flag = true
        },
        handlesec29blur(index){
            if(this.datainfo.secList2[index].insert){
                this.datainfo.secList2[index].flag = false
            this.LocalStorage()
            }else{
                alert("请输入内容")
            } 
           
        },
        handledata29close(index){
            this.datainfo.secList2.splice(index,1)
            this.LocalStorage()
        },
        handlesubmit29(){
            this.datainfo.secList2.push(this.sec29Click)
            let data = {
                insert:'',
                flag:false,
            }
            this.sec29Click = data
            this.sec_29_flag = false
            this.LocalStorage()
        },
        handleselectclose29(){
            this.sec_29_flag = false
        },
        handlesecfile(){
            this.pay_img_flag = true
        },
        handleFileChange(event){
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {
                // 创建一个URL对象，该对象表示参数中给定的文件
                let imageUrl = URL.createObjectURL(file);
                console.log(imageUrl);
                this.datainfo.pay_img = `<img src = "${imageUrl}">`
                this.LocalStorage()
                this.pay_img_flag = false
            } else {
                this.datainfo.pay_img = ``
                this.pay_img_flag = false
                alert('Please select a valid image file.');
            }
        },
        handleselect(){
            this.select_flag = true
          
        },
        handleselectclose(){
            this.select_flag = false
            this.value = '';
            let data = {
                date:'',
                color:'',
            }
            this.dataList = data
        },
        handlesubmit(){
            this.dataList.color = this.value
            let _index = this.datainfo.specialDates.findIndex(specialDate => specialDate.date ===  this.dataList.date);
            if(_index != -1){
                this.datainfo.specialDates.splice(_index,1,this.dataList)
            }else{
                this.datainfo.specialDates.push(this.dataList)
            }
            this.datainfo.specialDates = this.datainfo.specialDates.filter(item => item.color !== '' && item.color !== null);
            this.value = '';
            let data = {
                date:'',
                color:'',
            }
            this.dataList = data
            this.select_flag = false
            this.LocalStorage()
        },
        handledataclose(index){
            this.datainfo.dataLiist.splice(index,1)
            this.LocalStorage()
        },

        LocalStorage(){
            localStorage.setItem('Data', JSON.stringify(this.datainfo));
        },
        formattedDate(originalDate) {
            const date = new Date(originalDate);
            const today = new Date();
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const month = monthNames[date.getMonth()];
            const day = date.getDate().toString().padStart(2, '0'); // 确保日期始终是两位数
            
            if (
                date.getFullYear() === today.getFullYear() &&
                date.getMonth() === today.getMonth() &&
                date.getDate() === today.getDate()
            ) {
                return 'Today';
            } else {
                return `${month} ${day}`;
            }
        }
    }
   
  }
  </script>
  
  <style scoped >
        @import '@/assets/css/home.css'
  
  </style>
  